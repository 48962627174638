import React from "react"
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import Layout from "../components/layout"
import { Link as RouterLink } from "gatsby"
import HomeIcon from "@material-ui/icons/Home";
import Seo from "../components/seo"

const useStyles = makeStyles(theme => ({
  container: {
    padding: "2rem",
  },
  paragraph: {
    margin: "1rem auto",
    textAlign: "center"
  },
  links: {
    textDecoration: "none",
    color: "inherit",
    ":hover": {
      textDecoration: "underline",
    },
  },
}))

export default function NotFound() {
  const classes = useStyles()
  return (
    <Layout>
      <Seo title="404: Page not Found" />
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.paragraph}>
          <Button
            variant="outlined"
            style={{ margin: "2rem 0" }}
            startIcon={<HomeIcon />}
          >
            <RouterLink className={classes.links} to="/">
              Back to Home
            </RouterLink>
          </Button>
          <Typography variant="h5">
            The page you are looking for does not seem to be available. 
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}
